import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UploadDocumentsComponent } from './components/upload-documents/upload-documents.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ThanksComponent } from './components/thanks/thanks.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: "dua", pathMatch:"full"
  },
  {
    path: 'dua',
    component: UserDetailsComponent
  },
  {
    path: 'user-detail',
    component: UserDetailsComponent
  },
  {
    path: 'upload-documents',
    component: UploadDocumentsComponent
  },
  {
    path: 'confirm',
    component: ConfirmComponent
  },
  {
    path: 'thanks',
    component: ThanksComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<ngx-spinner bdColor="rgba(255,255,255,0.5)" size="default" color="#1A1446" type="ball-spin-clockwise"></ngx-spinner>
<div class="page-wrapper">
  <div>
    <app-header></app-header>
    <app-progress-bar></app-progress-bar>
  </div>
  <div class="main content-main " id="main">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
<div class="container">
    <div class="d-flex">
        <div class="img-manage">
            <img src="assets/images/Auto_RGB.png" width="40px"><br><span class="motor-head">{{'btn.motor' | translate}}</span>
        </div>
        <div class="img-manage main-head">
            <span>{{'confirm.confirm'|translate}}</span>
        </div>
    </div>
    <div class="row">
        <p *ngIf="!sessionValid"  class="error-msg pull-right text-center mb-0">{{selectedAppLang=='En'?errorMsg:errorMsgZh}}</p>   
   </div> 
    <div class="mt-4 ml-60 mb-4 display-boxes" *ngIf="data">
        <div class="width-block">
            <div class="mb-2">
                <div class="d-flex">
                    <i class="tick-icon fa fa-circle-check"></i><p>{{'confirm.name'|translate}}</p>
                </div>
                <span class="ans">{{nameMasking(data.customerDetails.name)}}</span>
            </div>
            <div class="mb-2">
                <div class="d-flex">
                    <i class="tick-icon fa fa-circle-check"></i><p>{{'confirm.hkid'|translate}}</p>
                    </div>
                <span class="ans">{{data.hkid}}</span>
            </div>
            <div class="mb-2">
                <div class="d-flex">
                    <i class="tick-icon fa fa-circle-check"></i>
                <p>{{'confirm.email'|translate}}</p>
                </div>
                <span class="ans" style="word-break: break-word;">{{emailMasking(data.customerDetails.email)}}</span>
            </div>
            <div>
                <div class="d-flex">
                    <i class="tick-icon fa fa-circle-check"></i> 
                <table>
                    <thead>
                        <!-- <div class="d-flex">
                            <i class="tick-icon fa fa-circle-check"></i> -->
                        <tr>
                            <th scope="col">{{'confirm.docName'|translate}}</th>
                            <th scope="col">{{'confirm.docType'|translate}}</th>
                        </tr>
                        <!-- </div> -->
                    </thead>
                    <tbody>
                        <tr *ngFor="let element of data.doc" >
                          <td>{{ element.docName }}</td>
                         <td> {{selectedAppLang=='En'?element.docType:element.docTypeZh}}</td>
                          <td><i class="icons fa fa-eye" aria-hidden="true"(click)="downloadDocument(element.docID)"></i></td>
        
                        </tr>
                        </tbody>
                </table>
                </div>
            </div>
        </div>
        <div class="width-block">
            <div class="mb-2">
                <div class="d-flex">
                    <i class="tick-icon fa fa-circle-check"></i>
                    <p>{{'confirm.vin'|translate}}</p>
                    </div>
                <span class="ans">{{data.customerDetails.vinNumber}}</span>
            </div>
            <div class="mb-2">
                <div class="d-flex">
                    <i class="tick-icon fa fa-circle-check"></i>
                <p>{{'confirm.model'|translate}}</p>
                </div>
                <span class="ans">{{data.customerDetails.model}}</span>
                        </div>
            <div class="mb-2">
                <div class="d-flex">
                    <i class="tick-icon fa fa-circle-check"></i>
                <p>{{'confirm.trim'|translate}}</p>
                </div>
                <span class="ans">{{data.customerDetails.trim}}</span>
            </div>
        </div>     
    </div>
    <div class="ml-60 warning-color"><p>{{'confirm.declaration'|translate}}</p></div>
    <div class="d-flex mt-4 mb-4">
        <div class="width-block">
            <button class="btn" (click)="back()">{{'btn.back'|translate}}</button>
        </div>
        <div class="width-block" style="text-align: right;">
            <button class="btn" (click)="submit()">{{'btn.submit'|translate}}</button>
        </div>
    </div>
</div>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DuaService {
  baseUrl = environment.baseUrl;
  chosenLang = new BehaviorSubject('');
  constructor(
    private httpClient: HttpClient
  ) {  
    this.chosenLang.next('')}

  updateLang(newlang: string) {
    this.chosenLang.next(newlang);
  }

  getUserDetails(body: any) {
    const url = this.baseUrl + 'authentication';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.httpClient.post<any>(url, body, { headers });
  }
  uploadDocuments(body: any, token: String,userToken:String) {
    console.log('body', body);
    console.log('token', token);

    const url = this.baseUrl + 'upload';
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token).set( "usertoken",userToken as string);
    headers.append('Content-Type', 'multipart/form-data');
    return this.httpClient.post<any>(url, body, { headers });
  }

  getUploadedDocument(referenceNumber: String, token: String,userToken:String) {
  //  console.log(referenceNumber)
    const url = this.baseUrl + `docDetails?duaReferenceNumber=${referenceNumber}`;
    console.log(url)
    const headers = new HttpHeaders({ 
      'Content-Type': 'application/json',
    "Authorization": "Bearer " + token,
    "usertoken":userToken as string});

    return this.httpClient.get<any>(url, { headers : headers});
  }

  downloadDocument(docId: any, referenceNumber: String, token: String,userToken:String) {
    console.log(token)
    console.log(docId)
    const url = this.baseUrl + `download?fileName=${docId}&duaReferenceNumber=${referenceNumber}`;
    console.log(url)
    const headers = new HttpHeaders({ 
      'Content-Type': 'application/json',
    "Authorization": "Bearer " + token, 
    "usertoken":userToken as string,
    responseType : 'blob'});
    

    console.log('headers', headers);
    return this.httpClient.get<any>(url, { headers : headers,responseType : 
      'blob' as 'json'});

  }

  deleteDocument(docId: String, referenceNumber: String, token: String,userToken:String) {

    const url = this.baseUrl + `delete?fileName=${docId}&duaReferenceNumber=${referenceNumber}`;
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("usertoken",userToken as string);
    headers.append('Content-Type', 'application/json');
    return this.httpClient.delete<any>(url, { headers });

  }

  confirm(body: any, token: String,userToken:String) {
    const url = this.baseUrl + 'confirm';
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("usertoken",userToken as string);
    headers.append('Content-Type', 'application/json');
    return this.httpClient.post<any>(url, body, { headers });

  }
  
  updateDocDetails(body: any, token: String,userToken:String) {
    const headers = new HttpHeaders().set("Authorization", "Bearer " + token).set("usertoken",userToken as string);
    headers.append('Content-Type', 'multipart/form-data');
    const url = this.baseUrl + 'updateDocType';

    return this.httpClient.post<any>(url, body, { headers });
  }
  
}

<div class="container" style="overflow: auto;">
    <div class="d-flex">
        <div class="img-manage">
            <img src="assets/images/Auto_RGB.png" width="40px"><br><span class="motor-head">{{'btn.motor' | translate}}</span>
        </div>
        <div class="img-manage main-head">
            <span>{{'upload.please'|translate}}</span>
        </div>       
    </div>
    <div class="row">
         <p *ngIf="!sessionValid"  class="error-msg pull-right text-center mb-0">{{selectedAppLang=='En'?errorMsg:errorMsgZh}}</p>   
    </div> 
    <div class="margin-24">
        <table>
            <thead>
                <tr>
                    <th scope="col">{{'upload.name'|translate}}</th>
                    <th scope="col">{{'upload.type'|translate}}</th>
                    <th scope="col">{{'upload.size'|translate}}</th>
                    <th scope="col">{{'upload.remark'|translate}}</th>
                    <th scope="col">{{'upload.function'|translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let element of elements;let i=index">
                    <td scope="row">{{ element.docName }}</td>
                    <!-- <td>{{element.type}}</td> -->
                    <!-- <td>    <select class="form-control" id="pCategory"  name="pCategory" [(ngModel)]=element.docType (change)="DroplistChange($event)"> -->
                    <td>    
                        <select class="form-control" id="pCategory"  name="pCategory" [(ngModel)]=element.docType (change)="DroplistChange($event, element, 'DOCTYPE')">                    
                            <option value="{{doclist.docType}}" *ngFor="let doclist of docType">{{selectedAppLang=='En'?doclist.docType:doclist.docTypeZh}}</option>
                            <!-- <option value="NCD">NCD</option> -->
                        </select>
                    </td>
                    <td>{{element.docSize}}</td>
                    <td style="width:20%; padding:5px">
                        <input class="form-control small-input" type="text" value="{{element.remark}}" (change)="DroplistChange($event, element, 'REMARKS')">
                    </td>

                    <td><i class="icons fa fa-eye" aria-hidden="true" (click)="downloadDocument(element.docID)"></i><i class="icons fa fa-trash"
                            aria-hidden="true" (click)="deleteDocument(element.docID,i)"></i></td>

                </tr>
            </tbody>
        </table>
    </div>
    <div class="dropzone margin-24 browse-sanction " >
        <input type="file" #fileDropRef id="fileDropRef" accept="pdf, png, png, jpeg" multiple (change)="fileBrowseHandler($any($event.target).files)">
        <h3>{{'upload.drag'|translate}}</h3>
        <!-- <h3>Or</h3> -->
        <button class="btn" for="fileDropRef">
            {{'btn.browse'|translate}}
        </button>
        <!-- <label for="fileDropRef">Browse</label> -->
    </div>
    <div class="margin-24 warning-msg">
        <img src="assets/images/warning.svg" width="24px">
        <div>
            <ul>
                <li>{{'upload.supportdoc_reqDocMsg' | translate}}
                    <div class="mb-2">
                        <div class="d-flex f-sizep">
                            <i class="tick-icon fa fa-circle-check"></i>
                            <p [innerHTML]="'upload.supportdoc_reqDoc1_page2'|translate"></p>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="d-flex f-sizep">
                            <i class="tick-icon fa fa-circle-check"></i>
                            <p [innerHTML]="'upload.supportdoc_reqDoc3_page2'|translate"></p>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="d-flex f-sizep">
                            <i class="tick-icon fa fa-circle-check"></i>
                            <p [innerHTML]="'upload.supportdoc_reqDoc4_page2'|translate"></p>
                        </div>
                    </div>
                </li>
                <li class="pt-2 warning-color">{{'note.note_one' | translate}}</li>
                <li class="pb-2 warning-color">{{'note.note_two' | translate}}</li>
                <li>{{'upload.supportdoc_line1' | translate}}</li>
                <li>{{'upload.supportdoc_line2' | translate}}</li>
                <li>{{'upload.supportdoc_line3' | translate}}</li>
                </ul>
        </div>
    </div>


    <div class=" row mt-4 laptopShow" >
        <div class="col">
            <button (click)="fileUpload.click()" class="btn btn-mob" style= "width:inherit;" for="fileDropRef">
                <input #fileUpload class="input" type="file" accept="pdf, png, png, jpeg" name="Browse" multiple="multiple" (change)="fileBrowseHandler($any($event.target).files)"> {{'btn.browse'|translate}}
            </button> 
            </div>  
        <div class="col" style="margin-left: -23px;">
           
            <button class="btn btn-mob" style= "width:inherit;" (click)="takePhoto.click();">Take Photo</button>
            <input #takePhoto class="input d-none" type="file" accept="image/*" capture="environment" (change)="fileBrowseHandler($any($event.target).files)">
            <!-- <button class="btn btn-mob" *ngIf="showCaptureB" style= "width:inherit;" (click)="triggerSnapshot();">Capture Photo</button> -->
        </div>  
        <div class="col-md-6">
  
            <div id="results"></div>
            <!-- <img src="{{ captureImage }}" height="300px"> -->
        </div>
    </div>
    <div class="row mt-4" >
        <!-- <p *ngIf="!selectdoc"  class="error-msg">Please Upload not document </p> -->
        <p *ngIf="selectdoc && sessionValid"  class="error-msg pull-right text-right mb-0">{{selectedAppLang=='En'?errorMsg:errorMsgZh}}</p>
    </div>
    
    <div class=" row mb-4 changev" >
        <!-- <div class="col">
            <button class="btn btn-mob setlap" style="width:inherit;">{{'btn.later'|translate}}</button>
        </div> -->
        <div class="col ">
            <button class="btn btn-mob setlap" style="width: inherit;float: right;" (click)="continue1()">{{'btn.continue'|translate}}</button>
        </div>
    </div>
    <!-- <webcam *ngIf="isPhoto" 

    [trigger]="triggerObservable" 
    (imageCapture)="handleImage($event)"></webcam> -->
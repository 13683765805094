import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DuaService } from 'src/app/core/services/dua.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  userDetails = {name:"", hkid:"", email: "", doc: [{docName:"", docType: "",docTypeZh:""}], vin:"", model: "", trim: ""}
  data:any;
  sessionValid:boolean =true;
  public errorMsg: string = ""
  public errorMsgZh:string =""
  selectedAppLang: any="";
  constructor(
    private router: Router,
    private location: Location,
    private spinnerService: NgxSpinnerService,
    private duaService: DuaService
  ) { }

  ngOnInit(): void {
    this.data = localStorage.getItem('userDetails');
   this.data = JSON.parse(this.data);
   console.log(this.data);
   this.selectedAppLang=localStorage.getItem('lang');
   this.duaService.chosenLang.subscribe(res=>{
    this.selectedAppLang=res==''?localStorage.getItem('lang'):res;
  });
  }

  emailMasking(email: String) {
    let index = email.indexOf("@");
    let end_id = email.substring(index);
    let mask = "";
    let size = email.substring(0, index).length;
    for (let i = 0; i<size;i++) {
      mask += "X";
    }
    // mask = email.substring(0,2)+mask+end_id;
     mask = mask+end_id;

    return mask;
  }

  nameMasking(name:String) {
    let allName = name.split(" ");
    console.log('all name', allName);
    let updatedName = "";
    for (let i = 0; i<allName.length; i++) {
      let mask = "";
      // console.log('name', allName[i]);
      // console.log('length', allName[i].length);

      for (let j=0; j<allName[i].length-1; j++) {
        mask += "X"
      }
      updatedName += allName[i].substring(0,1)+mask+" "
    }
    return updatedName;
  }

  submit() {
    if (this.data) {
      let documentDetails:any = []
      for (let i = 0; i < this.data.doc.length; i ++) {
        let b = {
          docId: this.data.doc[i].docId,
          remarks: this.data.doc[i].remark
        };
        documentDetails.push(b);
      }
      let body = {
        duaReferenceNumber:this.data.duaReferenceNumber,
        documentDetails:documentDetails

      }
      // console.log('body', body);
      this.spinnerService.show();
      this.duaService.confirm(body, this.data.authToken,this.data.userToken).subscribe(res=>{
        this.spinnerService.hide();
        this.sessionValid=true;
        this.router.navigateByUrl('thanks');
           
      },err=>{
         if(err.status==401)
           this.handleSession();
        this.spinnerService.hide();
      });
    }
    
   
  }

  back() {
    this.location.back();
  }

downloadDocument(docId: any) {
    console.log("actoin")
    this.spinnerService.show();
    this.duaService.downloadDocument(docId, this.data.duaReferenceNumber, this.data.authToken,this.data.userToken).subscribe((res) => {
     this.sessionValid=true
      console.log(docId)
      console.log( res);
      let blob = new Blob([res], { type: 'application/pdf' });
      var downloadURL = window.URL.createObjectURL(blob);
      console.log('blob')
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = `${docId}`;
      link.click();
      this.spinnerService.hide();
    }, err => {
      if(err.status==401)
        this.handleSession()
      console.log(err)
      this.spinnerService.hide();
    });
  }

  handleSession(){
    this.sessionValid=false;
    this.errorMsg='Session Expired. Please enter user details again'
    this.errorMsgZh='會話已過期。請再次輸入用戶信息'
  }

}

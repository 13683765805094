import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DuaService } from '../../core/services/dua.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  maskedEmail = "";
  maskedName = "";
  hkid: string = "";
  validURL:boolean = false
  idEntered = true;
  idIncorrect = false;
  caseCompleted: boolean = false;
  noSuchDuaRefNo: boolean = false;
  params: any = {};
  data: any = undefined;
  constructor(
    private router: Router,
    private authenticateService: DuaService,
    private activatedRoute: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    //
    this.activatedRoute.queryParams.subscribe(params => {
      const ref = params['ref'];
  
      var decodedString = atob(ref)
      const arr = decodedString.split("&");
      const paramsObj:{[key:string]:string} = {};
      for(let i=0; i<arr.length; i++){
        const [k, v] = arr[i].split('=');
        paramsObj[k] = v.replace("%20", " ");
      }
      console.log(paramsObj)
      this.params = paramsObj;

      this.maskedEmail = this.emailMasking(paramsObj.email);
      this.maskedName = this.nameMasking(paramsObj.name);
     
      console.log(this.router.url)
      
      // this.authenticate();

    });
   if(this.activatedRoute.snapshot.queryParams['ref']){
    this.validURL= true
   }else{
    this.validURL= false
   }

  }

  authenticate() {
    if (this.params) {
      let body = {
        "duaReferenceNumber": this.params.duaReferenceNumber,
        "authDetails": [
          {
            "idType": this.params.idType,
            "idName": this.hkid,
          }
        ]
      }
      this.spinnerService.show();
      console.log(body)
      this.authenticateService.getUserDetails(body).subscribe(res => {
        console.log('res', res);

        if (!res.error) {
          // this.maskedEmail = this.emailMasking(this.data.customerDetails.email);
          // this.maskedName = this.nameMasking(this.data.customerDetails.name);
          this.data = res;
          this.data['idType'] = this.activatedRoute.snapshot.queryParams['idType'];
          this.data['hkid']=this.hkid;
          localStorage.setItem('userDetails', JSON.stringify(this.data));
          this.router.navigateByUrl('/upload-documents');
        }
        else{
          if(res.error.message[0].code === 'E007'){
            this.idIncorrect = true;
          }
          else if(res.error.message[0].code === 'E010'){
            this.caseCompleted = true;
          }
          else if(res.error.message[0].code === 'E005'){
            this.noSuchDuaRefNo = true;
          }
        }
        this.spinnerService.hide();
      }, (err => {
        this.spinnerService.hide();
      }));
    } else {
      return;
    }
  }

  emailMasking(email: String) {
    let index = email.indexOf("@");
    let end_id = email.substring(index);
    let mask = "";
    let size = email.substring(0, index).length;
    for (let i = 0; i < size; i++) {
      mask += "X";
    }
    // mask = email.substring(0,2)+mask+end_id;
    mask = mask + end_id;

    return mask;
  }

  nameMasking(name: String) {
    let allName = name.split(" ");
    console.log('all name', allName);
    let updatedName = "";
    for (let i = 0; i < allName.length; i++) {
      let mask = "";
      console.log('name', allName[i]);
      console.log('length', allName[i].length);

      for (let j = 0; j < allName[i].length - 1; j++) {
        mask += "X"
      }
      updatedName += allName[i].substring(0, 1) + mask + " "
    }
    return updatedName;
  }

  continue() {
    console.log(this.hkid.length);
    console.log(this.hkid);

    //reset params
    this.idIncorrect = false;
    this.caseCompleted = false;
    this.noSuchDuaRefNo = false;

    if (this.hkid.length > 3) {
      this.idEntered = true;
    } else {
      this.idEntered = false;
    }
    if (this.idEntered) {
      this.authenticate();
    }
  }

  inputType() {
    this.idEntered = true;
  }

}

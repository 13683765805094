import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'DUA';
  constructor(
    private translate: TranslateService,
    private router:Router,
    private activatedRoute: ActivatedRoute,
  ) {}
  ngOnInit(): void {

    this.translate.setDefaultLang('en-HK');
   /*if (localStorage.getItem('lang')) {
      const selectedLang = localStorage.getItem('lang');
      if (selectedLang === 'En') {
        this.translate.setDefaultLang('en-HK');
        localStorage.setItem('lang', 'En');
      } else {
        this.translate.setDefaultLang('zh-HK');
        localStorage.setItem('lang', 'Zh');
      }
    }*/

    this.activatedRoute.queryParams.subscribe(params => {
      if(params.hasOwnProperty('ref')){
        console.log(params);
        const ref = params['ref'];
        console.log(ref);
    
        var decodedString = atob(ref)
        const arr = decodedString.split("&");
        const paramsObj:{[key:string]:string} = {};
        for(let i=0; i<arr.length; i++){
          const [k, v] = arr[i].split('=');
          paramsObj[k] = v.replace("%20", " ");
        }
        console.log(paramsObj)

        let langFromUrl = paramsObj.lang;
        if( langFromUrl === 'en-HK'){
          this.translate.setDefaultLang('en-HK')
          localStorage.setItem('lang', 'En');
        }
        else if( langFromUrl === 'zh-HK'){
          this.translate.setDefaultLang('zh-HK')
          localStorage.setItem('lang', 'Zh');
        }
      }
    
    })

  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {

  data:any;
  constructor() { }

  ngOnInit(): void {
    this.data = localStorage.getItem('userDetails');
    this.data = JSON.parse(this.data);
    console.log(this.data);
  }

}
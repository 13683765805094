<div class="container">
    <div class="row">
        <div class="col-12 col-md-8">
            <div *ngIf="!validURL">
                <div class="static-txt">

                    We are sent you a mail for access valid url
                </div>
            </div>
            <div *ngIf="validURL">
                <div class="d-flex" style="justify-content: center;">
                    <!-- <div class="mt-4" style="margin-right: 50px;">
                        <p class="heading">Motor</p>
                    </div> -->
                    <div class="d-flex">
                        <div class="img-manage">
                            <img src="assets/images/Auto_RGB.png" width="40px"><br><span
                                class="motor-head">{{'btn.motor' | translate}}</span>
                        </div>
                        <!-- <div class="img-manage">
                            <span>{{'user.enter'|translate}}</span>
                        </div> -->
                    </div>
                    <div class="mt-4">
                        <div>
                            <span class="main-head">
                                {{'user.enter' | translate}}
                            </span>
                        </div>
                        <div class="bw-fields">
                            <span class="heading">
                                {{'user.name'|translate}}
                            </span><br>
                            <span>
                                {{maskedName}}
                            </span>
                        </div>

                        <div class="bw-fields">
                            <span class="heading">
                                {{'user.email'|translate}}
                            </span><br>
                            <span style="word-break: break-word;">
                                {{maskedEmail}}
                            </span>
                        </div>
                        <!--
                        <div class="bw-fields">
                            <span class="heading">
                                {{'user.vin'|translate}}
                            </span><br>
                            <span *ngIf="data">
                                {{data.customerDetails.vinNumber}}
                            </span>
                        </div>
                    -->

                        <div class="bw-fields mb-4">
                            <span class="heading">
                                {{'user.hkid'|translate}}
                            </span><i class="tool-tip-cl fa fa-info-circle" aria-hidden="true" data-toggle="tooltip"
                                title="First 4 digit required only.​"></i><br>
                            <input id="hkid" class="inp-field" [ngClass]="{'error-inp':!idEntered}" type="text"
                                [(ngModel)]="hkid" placeholder="A123" maxlength="4" (input)="inputType()"
                                oninput="this.value = this.value.toUpperCase()">
                            <!-- if they request only first char uppercase, then change on input to this-->
                            <!-- oninput="this.value = this.value[0].toUpperCase() + this.value.slice(1)" -->

                            <span *ngIf="!idEntered" class="error-msg">{{'user.err_firstFour' | translate}}</span>
                            <span *ngIf="idIncorrect" class="error-msg">{{'user.err_hkidInCorrect' |
                                translate}}</span>
                            <span *ngIf="caseCompleted" class="error-msg">{{'user.err_completed' |
                                translate}}</span>
                            <span *ngIf="noSuchDuaRefNo" class="error-msg">{{'user.err_completed' |
                                translate}}</span>
                        </div>
                        <div class="btn-align">
                            <button class="btn-cont" (click)="continue()">
                                {{'btn.continue'|translate}}
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="msgWrap mt-4">
                <div class="margin-24 warning-msg">
                    <img src="assets/images/warning.svg" width="24px">
                    <div>
                        <p>{{'user.msg_warning' | translate }}                       </p>

                    </div>
                </div>
                <div class="msglist mt-4">
                    <div class="mb-2">
                        <div class="d-flex f-sizep">
                            <i class="tick-icon fa fa-circle-check"></i>
                            <p [innerHTML]="'upload.supportdoc_reqDoc1'|translate"></p>
                        </div>
                    </div>
                    <div class="mb-2">
                        <div class="d-flex f-sizep">
                            <i class="tick-icon fa fa-circle-check"></i>
                            <p [innerHTML]="'upload.supportdoc_reqDoc4'|translate"></p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="f-sizep p-2 warning-color"> <p>{{'note.note_title'|translate}}</p></div>
                    <ul>
                        <li class="f-sizep warning-color"><p>{{'note.note_one'|translate}}</p></li>
                        <li class="f-sizep warning-color"><p>{{'note.note_two'|translate}}</p></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  isLanguageChange: boolean = false;
  // langChange: Subject<boolean> = new Subject<boolean>();
  
  constructor(
    private httpClient: HttpClient,
  ) {
  //   this.langChange.subscribe(() => {
  //     this.isLanguageChange = true;
  // });
  }
}

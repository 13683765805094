<div class="container">
    <div class="d-flex">
        <div class="img-manage">
            <img src="assets/images/Auto_RGB.png" width="40px"><br><span class="motor-head">{{'btn.motor' | translate}}</span>
        </div>
    </div>
    <div class="main-box">
        <div class="mb-2">
            {{'thankYou.Thankline1' | translate}}
        </div>
        <div class="mb-2">
            {{'thankYou.Thankline2' | translate}}
        </div>
    <div class="mb-2 reference">
            <span> {{'thankYou.referenceNo' | translate}}​</span><br>
              <span>{{data.customerDetails.coverNoteNo ?? data.duaReferenceNumber}}</span>
              <!-- leave a fallback here, if no cnNo shown, then go with duaRefNo-->
        </div>
    </div>
</div>

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DuaService } from 'src/app/core/services/dua.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import {
  DataUrl,
  DOC_ORIENTATION,
  NgxImageCompressService,
  UploadResponse,
} from 'ngx-image-compress';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {
  elements: any = [];
  selectedType = 'NCD';
  isPhoto = false;
  eleobj: any = {}
  data: any;
  docType: any = {}
  docExeArray: any = []
  docManArray: any = []
  public errorMsg: string = "Hello"
  public errorMsgZh:string =""
  selectdoc: boolean = false
  acceptMimeType: any = []
  selectedAppLang: any="";
  sessionValid:boolean = true;
  localUrl: any;
  isFlip: boolean = false;
  
  constructor(
    private imageCompress: NgxImageCompressService,
    private router: Router,
    private duaService: DuaService,
    private spinnerService: NgxSpinnerService
    
  ) { }

  @ViewChild('fileDropRef') fileData: any;
  ngOnInit(): void {
    this.data = localStorage.getItem('userDetails');
    this.data = JSON.parse(this.data);  
    this.selectedAppLang=localStorage.getItem('lang');
    this.getUploadedDocument();
    this.duaService.chosenLang.subscribe(res=>{
      this.selectedAppLang=res==''?localStorage.getItem('lang'):res;
    });
  }

 

  showCaptureB: Boolean = false;

  getUploadedDocument() {
    this.duaService.getUploadedDocument(this.data.duaReferenceNumber, this.data.authToken,this.data.userToken).subscribe(res => {
      this.sessionValid=true
      // console.log(res)
      this.docType = res.dropdownList
      // console.log(this.docType)
      // console.log("res length", res)
      let resdata = res.documentDetails

      if( this.docType.length > 0 ) {
       this.selectedType = this.docType[0].docType;
        //assume all file have same mimeType accepted
        this.acceptMimeType = this.docType[0].acceptMimeType;
      }
      
      for (let i = 0; i < this.docType.length; i++) {
        let obj = { 'type': '', 'value': 0, 'isMandatory': false, 'acceptMimeType': "",'typeZh':'' }
        obj.type = this.docType[i].docType;
        obj.typeZh=this.docType[i].docTypeZh;
        obj.value = 0
        obj.isMandatory = this.docType[i].isMandatory
        obj.acceptMimeType = this.docType[i].acceptMimeType
        this.docExeArray.push(obj)
        // console.log(this.docExeArray)
        // this.docManArray[this.docType[i].docType] = this.docType[i].isMandatory
      }

      
      for (let i = 0; i < resdata.length; i++) {
        // console.log("dfsdfs")
        let siz = resdata[i].docSize / 1024;
        siz = Math.round(siz * 100) / 100;
        let roundedSize = String(siz) + 'kb';
        // console.log('roundedSize', roundedSize);
        let remarks = resdata[i].remarks = null ? '' : resdata[i].remarks;
        this.eleobj = { docName: resdata[i].docName, docSize: roundedSize, remark: remarks, docType: resdata[i].docType, docTypeZh: resdata[i].docTypeZh, docID: resdata[i].docID };
        // this.elements = resdata.eleobj;
        this.elements.push(this.eleobj);
      }
      // this.elements = res.documentDetails
      // console.log(   this.elements)
      // if( this.elements.docName == null){
      //   return
      // }else{
      //   this.elements = res.documentDetails
      // console.log(   this.elements)
      // }

    },err=>{
      if(err.status==401)
      this.handleSessionValidity()
    })
  }

  async fileBrowseHandler(file: any) {
    console.log('fileBrowseHandler', file);
    const formData = new FormData();
    var  fileName : any;
    this.spinnerService.show();

    for (let i = 0; i < file.length; i++) {
      console.log(file[i].type);
      if(!this.acceptMimeType.includes(file[i].type)){
        alert('file type not supported!');
        this.fileData.nativeElement.value = ''
        this.spinnerService.hide();
        return;
      }
      if(this.checkIfContainsInvalidChar(file[i].name.replace(/\.[^/.]+$/, ""))){ // remove the file extension first
        alert('file name contains invalid character!');
        this.fileData.nativeElement.value = ''
        this.spinnerService.hide();
        return;
      }
      // AMCMHK-6624 remove passwork checking, but just keep the code here first to prevent if change later
      // let isPwProtected = this.checkIfIsPasswordProtected(file[i]);
      // if(await isPwProtected){
      //   alert('file is password protected!');
      //   this.fileData.nativeElement.value = ''
      //   return;
      // }
      
      if( (file[i].type === 'image/png' || file[i].type === 'image/jpeg') && file[i].size > 5242880 ){
        
        console.log('file size', file[i].size);

        fileName = file[i].name;          
         const contentBuffer = await this.readAsync(file[i]);
         const resobj = await this.compressFile(contentBuffer,fileName);
         formData.append('file', this.dataURLtoFile(resobj, fileName));

      }else{
        formData.append('file', file[i]);
      }    
        
    }

    formData.append('duaReferenceNumber', this.data.duaReferenceNumber);
    formData.append('docType', this.selectedType);
   
    this.duaService.uploadDocuments(formData, this.data.authToken,this.data.userToken).subscribe(res => {
    
     if (res && res.length > 0) {
        this.sessionValid=true
        for (let i = 0; i < res.length; i++) {
          let siz = res[i].size / 1024;
          siz = Math.round(siz * 100) / 100;
          let roundedSize = String(siz) + 'kb';
          console.log('roundedSize', roundedSize);
          this.eleobj = { docName: res[i].docName, docSize: roundedSize, remark: "", docType: res[i].docType, docID: res[i].docId};
          this.elements.push(this.eleobj);
        }
        this.fileData.nativeElement.value = ''
        //Reset error message
        this.errorMsg=''
        this.errorMsgZh=''
        this.spinnerService.hide();
     }
    }, err => {
      if(err.status==401)
        this.handleSessionValidity();    
      this.spinnerService.hide();
    });
  }  

  readAsync(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(reader.result as string);
        };
      reader.onerror = () => {
          reject (new Error ('Unable to read..'));
      };
      reader.readAsDataURL(file);
    });
  }

  compressFile(image : any,fileName : any) {
   
   return new Promise<string>((resolve, reject) => {
      
      console.log('Size in bytes is now:',  this.imageCompress.byteCount(image).toString());
      
      this.imageCompress.compressFile(image, DOC_ORIENTATION.Default, 40, 50).then(
        result => {
          resolve (result);
          console.log('Size in bytes after compression:',  this.imageCompress.byteCount(result).toString());
        }
      ).catch((error)=> {
        reject(error);
      });  
        
    });
  }

  dataURLtoFile(dataURI: string, filename: string) {   

      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0]; 
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        const bstr = atob(dataURI.split(',')[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      }
      return "";   
 }

  downloadDocument(docId: String) {
    console.log(docId)
    this.spinnerService.show();
    this.duaService.downloadDocument(docId, this.data.duaReferenceNumber, this.data.authToken,this.data.userToken).subscribe((res) => {  
        console.log(docId)
        console.log(res);
        this.sessionValid=true;
        let blob = new Blob([res], { type: 'application/pdf' });
        var downloadURL = window.URL.createObjectURL(blob);
        console.log('blob')
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${docId}`;
        link.click();
        this.spinnerService.hide();
    }, err => {
      if(err.status==401)
        this.handleSessionValidity(); 
      console.log(err)
      this.spinnerService.hide();
    });
  }

  deleteDocument(docId: String, index: any) {
    this.spinnerService.show();
    this.duaService.deleteDocument(docId, this.data.duaReferenceNumber, this.data.authToken,this.data.userToken).subscribe(res => {
      console.log('res', res);
      this.spinnerService.hide();
      if (res && res.status == "200") {
        this.sessionValid=true;
        this.elements.splice(index, 1); // 2nd parameter means remove one item only
      }
    }, err => {
      if(err.status==401)
        this.handleSessionValidity();
      this.spinnerService.hide();
    });
  }

  DroplistChange(val: any, currentArr: any, type: any){
    // console.log("Dropdown Value Changes:: ", val.target.value);
    // console.log("Dropdown Value Changes Array:: ", currentArr);
    // let listValue = val.target.value
    let body = {
      "duaReferenceNo": this.data.duaReferenceNumber, 
      "docId": currentArr.docID,
      "docType": "", 
      "remarks": ""
    }
    if( type === 'DOCTYPE' ) {
      body.docType = val.target.value;
      body.remarks = currentArr.remark
    } else {
      body.docType = currentArr.docType;
      body.remarks = val.target.value
    }
    
    this.spinnerService.show();
    this.duaService.updateDocDetails(body, this.data.authToken,this.data.userToken).subscribe(res => {
      this.sessionValid=true
       //Reset error message
       this.errorMsg=''
       this.errorMsgZh=''
      this.spinnerService.hide();
    }, err => {
      if(err.status==401)
       this.handleSessionValidity()
      console.log(err)
      this.spinnerService.hide();
    });
  }

  continue1() {
    if(this.sessionValid){
    this.selectdoc = false
    this.errorMsg = ''
    this.errorMsgZh=''
    this.docExeArray.forEach((res: any) => {
      res.value = 0
    });
    this.data['doc'] = this.elements;
    const docArr = this.elements
    const dDocType = this.docType

    let elem: any
    let count = 0
    let vrdCount = 0

    for (let i = 0; i < this.docExeArray.length; i++) {
      // console.log(docArr[i].docType)
      for (let j = 0; j < docArr.length; j++) {
        if (this.docExeArray[i].type === docArr[j].docType) {

          this.docExeArray[i].value++
        }
      }
      // console.log('i',i)
      // console.log("this.docExeArray[0].type", this.docExeArray[0].type)
      // if (this.docExeArray[i].value < 1 && this.docExeArray[i].isMandatory == true) {
      //   this.selectdoc = true
      //   this.errorMsg = `Please uplaod ${this.docExeArray[i].type} document`
      //   console.log('errormsg', this.errorMsg)
      // }


    }

    console.log(this.docExeArray)

  

    for (let i = 0; i < this.docExeArray.length; i++) {
      let obj = this.docExeArray[i]

      if(obj.isMandatory ==true && obj.value>=1){
        console.log(obj)
        this.selectdoc = false;
        this.errorMsg ='';
        this.errorMsgZh='';
        break;
      }
      else if (obj.isMandatory == true && obj.value <1) {
        console.log(obj)
        this.selectdoc = true
        this.errorMsg =`Please upload ${obj.type} document`
        this.errorMsgZh=`請上傳 ${obj.typeZh}`;
      }

      if(obj.type === 'Please Select' && obj.value > 0){
        this.selectdoc = true
        this.errorMsg =`Please select document type`
        this.errorMsgZh=`請選擇文件類型`
        break;
      }
    }

    if(!this.selectdoc){
      this.router.navigateByUrl('confirm');
    }else{
      
    }


    localStorage.setItem('userDetails', JSON.stringify(this.data));

    if (this.elements.length > 0) {
      // console.log("true")
      // this.router.navigateByUrl('confirm');
    }
    else {
      // console.log("false")
      this.selectdoc = true
      this.errorMsg = "Please Upload document "
      this.errorMsgZh="請上傳文件"
    }
  }

  }

  checkIfContainsInvalidChar(filename: string) : boolean{
    const reg = new RegExp("[^A-Za-z0-9_\-\\s]");
    return reg.test(filename);
  }

  async checkIfIsPasswordProtected(file: any) : Promise<Boolean>{
    try{
      let text = await this.readFile(file);
      return text.substring(text.lastIndexOf("<<"), text.lastIndexOf(">>")).includes("/Encrypt");
    }
    catch(error){
      return false;
    }
  }

  readFile(file: any) : Promise<string>{
    return new Promise(function(resolve, reject){
      var reader = new FileReader();

      reader.onload = function(){
        resolve(reader.result as string);
      }

      reader.onerror = function(){
        reject(reader.error);
      }

      reader.readAsText(file);
    })
  }

  handleSessionValidity(){
        this.sessionValid=false;
        this.errorMsg='Session Expired. Please enter user details again'
        this.errorMsgZh='會話已過期。請再次輸入用戶信息'
  }

  // private trigger: Subject<any> = new Subject();

  //   public webcamImage!: WebcamImage;
  //   private nextWebcam: Subject<any> = new Subject<any>();

  //   captureImage  = '';



  // public triggerSnapshot(): void {
  //     this.isPhoto = true;
  //     this.showCaptureB=true;

  //     setTimeout(()=>{
  //       this.trigger.next();
  //     },1000)
  // }

  // public handleImage(webcamImage:any): void {
  //     this.webcamImage = webcamImage;
  //     this.captureImage = webcamImage!.imageAsDataUrl;
  //     console.info('received webcam image', this.captureImage);
  // }


  // public get triggerObservable(): Observable<any> {

  //     return this.trigger.asObservable();
  // }


  // public get nextWebcamObservable(): Observable<any> {

  //     return this.nextWebcam.asObservable();
  // }



}

import { Component, OnInit } from '@angular/core';
import { Router , NavigationStart, Event as NavigationEvent, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  index = 0
  currentStatus = ''
  failedStatus : any = [];
  failed = false;
  constructor(
    private router: Router
  ) { 
    router.events.subscribe((event) => {
      let pathStatus = this.router.url.split("/");
      this.failedStatus = pathStatus;
      pathStatus = pathStatus[1].split("?")

      if (pathStatus.length > 0 && (pathStatus[0] == '' || pathStatus[0] == 'dua')) {
        this.index = 0;
        this.currentStatus = 'User Details';
      } else if (pathStatus.length > 0 && pathStatus[0] == 'upload-documents') {
        this.index = 1;
        this.currentStatus = 'Upload Documents';
      } else if (pathStatus.length > 0 && pathStatus[0] == 'confirm') {
        this.index = 2;
        this.currentStatus = 'Confirmation'
      } else if (pathStatus.length > 0 && pathStatus[0] == 'thanks') {
        this.index = 3;
        this.currentStatus = 'Acknowledgment'
      }
      
    })
  } 

  ngOnInit(): void {
  }

  backtoFirstPage(): void{
    let userDtl = localStorage.getItem('userDetails')!;
    let strUserDtl = JSON.parse(userDtl);
    let {duaReferenceNumber, customerDetails, idType} = strUserDtl;
    const params : NavigationExtras = {
      queryParams: {
        'ref': btoa('duaReferenceNumber='+duaReferenceNumber
          +'&email='+customerDetails.email
          +'&name='+customerDetails.name
          +'&idType='+ idType)
      }
    } ;
    this.router.navigate([''], params);
  }

  backtoSecondPage(): void{
    this.router.navigate(['upload-documents']);
  }

}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, UrlTree } from '@angular/router';
// import { Images } from '../../../shared/constants/index';
// import { RouteUtils } from '../../../shared/utils/route-utils';
// import { LanguageSwitchUtils } from '../../../shared/utils/language-switch';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';
// import { QuestionaireService } from '../../services/questionaire.service';
import { DuaService } from 'src/app/core/services/dua.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  selected = 'ENG';
  // logo = Images['LIBERTY_LOGO'];
  languages = ['繁體中文', 'ENG'];
  languageSelected = 'ENG';
  urlTranslationKey: string = '';
  isLanguageSwitchDisabled: boolean = false;
  isChinese = false;
  paramData: any = [];
  isLangChange: boolean = false;
  showLang = false;
  
  constructor(
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private titleService: Title,
    // private questionaireService: QuestionaireService,
    private metaService: Meta,
    private duaService: DuaService
  ) { }

  ngOnInit(): void {

    if (localStorage.getItem('lang')) {
      const selectedLang = localStorage.getItem('lang');
      if (selectedLang === 'En') {
        this.isChinese = false;
        this.languageSelected = '繁體中文';
        // this.titleService.setTitle('Overseas StudentCare');
        this.setEnglish();
      } else {
        this.isChinese = true;
        this.languageSelected = 'ENG';
        // this.titleService.setTitle('升學易');
        this.setChinese();
      }
    } else {
      this.translate.setDefaultLang('en-HK');
      this.isChinese = false;
      this.languageSelected = '繁體中文';
      localStorage.setItem('lang', 'En');
      // this.titleService.setTitle('Overseas StudentCare');
      this.setEnglish();

    }

    

  }

  // method to change language
  languageSelect(lang:String) {
    if (lang === "eng") {
      this.translate.setDefaultLang('en-HK');
      this.languageSelected = '繁體中文';
      this.isChinese = false;
      localStorage.setItem('lang', 'En');
      // this.titleService.setTitle('Overseas StudentCare');
      this.setEnglish();
      this.duaService.updateLang('En');
    } else {
      this.translate.setDefaultLang('zh-HK');
      this.languageSelected = 'ENG';
      this.isChinese = true;
      localStorage.setItem('lang', 'Zh');
      // this.titleService.setTitle('升學易');
      this.setChinese();
      this.duaService.updateLang('Zh');
    }
    // this.questionaireService.toggleLangChange();
    this.showLang = false;
  }

  setEnglish() {
    this.titleService.setTitle('DUA');
    // this.metaService.updateTag(
    //   { name: 'keywords', content: 'Overseas Student Insurance, Best overseas student insurance, Overseas study insurance, Student insurance Hong Kong, Recommend Overseas student insurance , Preparations for overseas studies, Liberty Insurance, Overseas Student, Exchange programme, Exchange student, Exchange student insurance, Oversea Student insurance coverage, Student insurance Comparison, Overseas study Insurance fee, Study abroad programs, Study aboard UK, Overseas study Insurance England, Study aboard Japan, Overseas study insurance Japan, Study aboard Australia, Overseas study Insurance Australia, Study aboard US, Overseas study Insurance United States, Study aboard Canada, Overseas Study Insurance Canada, Overseas Student Health Cover, Quick Quote, Online claim, Apply Online, Simple Application, Medical Expenses, Outpatient, Personal Accident, Travel Delay, Baggage Delay, Trip Cancellation, Overseas Residence Protection, Personal Property, Education Fund, Personal Liability, Scholarship Rewards, Leisure sports, Skiing, Sea diving' }
    // );
    // this.metaService.updateTag(
    //   { name: 'description', content: 'Liberty’s Overseas StudentCare provides you comprehensive coverage while you are studying on board, including medical expenses, personal incidents and many more. Giving you peace of mind to enjoy your overseas learning experience. Quote and Buy Now!' }
    // )
  }

  setChinese() {
    this.titleService.setTitle('檔案上載系統');
    // this.metaService.updateTag(
    //   { name: 'keywords', content: '海外留學保險, 海外留學保險 好, 留學生保險, 留學生保險 香港, 留學保險推薦, 升學準備, 利寶保險, 海外留學生, 海外交流, 交流生, 交流生保險, 留學生保險保障範圍, 留學生保險比較, 留學保險費用, 留學課程, 英國升學, 英國留學保險, 日本升學, 日本留學保險, 澳洲升學, 澳洲留學保險, 美國升學, 美國留學保險, 加拿大升學, 加拿大留學保險, 海外留學生健康保障, 快速報價, 網上索償, 網上投保, 投保簡單, 醫療費用, 門診, 個人意外, 旅程延誤, 行李延誤, 旅程取消, 海外住所保障, 個人財物, 教育基金, 個人責任, 獎學金, 休閒運動, 滑雪, 潛水' }
    // );
    // this.metaService.updateTag(
    //   { name: 'description', content: '利寶的「升學易」為您提供於海外留學期間的全面保障，涵蓋個人意外、醫療費用及更多保障，讓您安心享受海外學習過程。立即投保!' }
    // )
  }
  home() {
    this.router.navigateByUrl('/');
  }
  showLangBox() {
    if (this.showLang) {
    this.showLang = false;
    } else {
      this.showLang = true;
      }
  }
}

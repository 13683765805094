<nav class="d-flex align-items-center primary app-header ">
    <div class="container d-flex flex-wrap align-lang justify-content-between">
        <div class="col-md-8 d-flex">
            <h1 class="f-logo">
                <img src="assets/images/Liberty_Ins_TM.png" class="logo" width="164" alt="liberty insurance logo" (click)="home()">
            </h1>
            <a class="d-none m-logo" href="#">
                <img src="assets/images/liberty-insurance-logo-sm.png" class="logo" width="45" alt="liberty insurance logo sm">
            </a>
            <h1 class="line">|</h1>

            <div class="label-align lbl-align">
                <p>{{'header.title' | translate}}</p>
            </div>
        </div>
        <div class="col-md-4 d-flex align-items-center pb-0 justify-content-end apphdr-rt">
            <!-- <div>
                <ng-container>
                    <button (click)="languageSelect()"  class="btn">{{languageSelected}}</button>
                </ng-container>
            </div> -->
            <div class="make-circle" (click)="showLangBox()">
                <i class="fa fa-bars" aria-hidden="true"></i>
            </div>
            <div *ngIf="showLang" class="lang-box">
                <p (click)="languageSelect('eng')">English</p>
                <p (click)="languageSelect('chinese')">繁體中文</p>
            </div>
        </div>

    </div>
</nav>

<div class="main-bar-wrapper">
    <div class="container">
        <div class="status_travel ">
            <div class="grey_arrow"></div>
            <div class="outer_status status_travel" *ngIf="index == 0">

                <div class="elem_of_status active_elem_of_status pl-0">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">1 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.User_Details' | translate}}</p>
                            <span>{{'progressbar.upload_Documents' |translate }}</span>
                        </div>
                    </div>

                    <div class="desktop-bar">
                        <div class="left"></div>
                        <img src="../../../../assets/images/userdetail.png" alt="plane">
                        <p>{{'progressbar.User_Details' | translate }}</p>
                        <div class="right"></div>
                    </div>
                </div>
                <div class="elem_of_status not_active_status_nooow">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">2 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.upload_Documents' |translate }}</p>
                            <span>{{'progressbar.confirmation' | translate}}</span>

                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <img src="../../../../assets/images/uploaddoc.png" alt="success">
                        <p>{{'progressbar.upload_Documents' | translate }}</p>
                        <div class="right"></div>
                    </div>

                </div>

                <div class="elem_of_status not_active_status_nooow">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">3 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.confirmation' | translate}}</p>
                            <span>{{'progressbar.acknowledgement' | translate }}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <img src="../../../../assets/images/receipts.png" alt="success">
                        <p>{{'progressbar.confirmation' | translate  }}</p>
                        <div class="right"></div>
                    </div>
                </div>
                <div class="elem_of_status not_active_status_nooow">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">4 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.acknowledgement'  | translate }}</p>

                            <span>{{'progressbar.final_Step' | translate}}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <img src="../../../../assets/images/submit.png" alt="success">
                        <p>{{'progressbar.acknowledgement'  | translate }}</p>

                        <div class="right"></div>
                    </div>

                </div>
            </div>

            <div class="outer_status status_travel" *ngIf="index == 1">

                <div class="elem_of_status active_from_prev_step" (click)="backtoFirstPage()">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">1 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.User_Details' | translate}}</p>
                            <span>{{'progressbar.upload_Documents' | translate }}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <!-- <img src="../../../../assets/images/white_success.png" class="white_arrow_displ" alt="plane">  -->
                        <img src="../../../../assets/images/userdetail.png" alt="blue_arrow" class="blue_arrow_png">
                        <p>{{'progressbar.User_Details' | translate}}</p>
                        <div class="right"></div>
                    </div>
                </div>

                <div class="elem_of_status active_elem_of_status  pl-0">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">2 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.upload_Documents' | translate }}</p>
                            <span>{{'progressbar.confirmation' | translate  }}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <img src="../../../../assets/images/uploaddoc.png" alt="success">
                        <p>{{'progressbar.upload_Documents' | translate }}</p>
                        <div class="right"></div>
                    </div>

                </div>
                <div class="elem_of_status">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">3 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.confirmation' | translate  }}</p>
                            <span>{{'progressbar.acknowledgement'  | translate }}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <img src="../../../../assets/images/receipts.png" alt="success">
                        <p>{{'progressbar.confirmation' | translate  }}</p>
                        <div class="right"></div>
                    </div>

                </div>
                <div class="elem_of_status">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">4 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.acknowledgement'  | translate }}</p>
                            <span>{{'progressbar.final_Step' | translate}}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <img src="../../../../assets/images/submit.png" alt="success">
                        <p>{{'progressbar.acknowledgement'  | translate }}</p>
                        <div class="right"></div>
                    </div>
                </div>
            </div>

            <div class="outer_status status_travel" *ngIf="index == 2">

                <div class="elem_of_status active_from_prev_step" (click)="backtoFirstPage()">

                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">1 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.User_Details' | translate}}</p>
                            <span>{{'progressbar.upload_Documents' | translate }}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <img src="../../../../assets/images/userdetail.png" alt="success">
                        <p>{{'progressbar.User_Details' | translate}}</p>
                        <div class="right"></div>
                    </div>
                </div>
                <div class="elem_of_status delta_act_stick   active_from_prev_step" (click)="backtoSecondPage()">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">2 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.upload_Documents' | translate }}</p>
                            <span>{{'progressbar.confirmation' | translate  }}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <img src="../../../../assets/images/uploaddoc.png" alt="success">
                        <p>{{'progressbar.upload_Documents' | translate }}</p>
                        <div class="right"></div>
                    </div>
                </div>
                <div class="elem_of_status  active_elem_of_status pl-0">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">3 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.confirmation' | translate  }}</p>
                            <span>{{'progressbar.acknowledgement'  | translate }}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <!-- <img src="../../../../assets/images/plane.png" alt="plane" *ngIf="!failed"> -->
                        <img src="../../../../assets/images/receipts.png" alt="plane">
                        <p>{{'progressbar.confirmation' | translate  }}</p>
                        <div class="right"></div>
                    </div>

                </div>
                <div class="elem_of_status not_active_status_nooow">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">4 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.acknowledgement'  | translate }}</p>
                            <span>{{'progressbar.final_Step' | translate}}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <img src="../../../../assets/images/submit.png" alt="success">
                        <p>{{'progressbar.acknowledgement'  | translate }}</p>
                        <div class="right"></div>
                    </div>
                </div>
            </div>

            <div class="outer_status mlauto" *ngIf="index == 3">


                <div class="elem_of_status active_from_prev_step">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">1 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.User_Details' | translate}}</p>
                            <span>{{'progressbar.upload_Documents' | translate }}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <!-- <img src="../../../../assets/images/userdetail.png" class="white_arrow_displ" alt="plane"> -->
                        <img src="../../../../assets/images/userdetail.png" alt="blue_arrow" class="blue_arrow_png">
                        <p>{{'progressbar.User_Details' | translate}}</p>
                        <div class="right"></div>
                    </div>
                </div>
                <div class="elem_of_status delta_act_stick   active_from_prev_step">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">2 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.upload_Documents' | translate }}</p>
                            <span>{{'progressbar.confirmation' | translate  }}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <!-- <img src="../../../../assets/images/white_success.png" class="white_arrow_displ" alt="plane"> -->
                        <img src="../../../../assets/images/uploaddoc.png" alt="blue_arrow" class="blue_arrow_png">
                        <p>{{'progressbar.upload_Documents' | translate }}</p>
                        <div class="right"></div>
                    </div>

                </div>
                <div class="elem_of_status delta_act_stick  active_from_prev_step">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">3 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.confirmation' | translate  }}</p>
                            <span>{{'progressbar.acknowledgement'  | translate }}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <!-- <img src="../../../../assets/images/white_success.png" class="white_arrow_displ" alt="plane"> -->
                        <img src="../../../../assets/images/receipts.png" alt="blue_arrow" class="blue_arrow_png">
                        <p>{{'progressbar.confirmation' | translate  }}</p>
                        <div class="right"></div>
                    </div>

                </div>
                <div class="elem_of_status delta_act_stick   active_elem_of_status last_of_tradee">
                    <div class="mobile-bar">
                        <div class="radialProgressBar progress-25">
                            <div class="overlay">4 of 4</div>

                        </div>
                        <div class="bar-detail">
                            <p>{{'progressbar.acknowledgement'  | translate }}</p>
                            <span>{{'progressbar.final_Step' | translate}}</span>
                        </div>
                    </div>
                    <div class="desktop-bar">
                        <div class="left"></div>
                        <!-- <img src="../../../../assets/images/white_success.png" class="white_arrow_displ" alt="plane"> -->
                        <img src="../../../../assets/images/submit.png" alt="blue_arrow" class="blue_arrow_png">
                        <p>{{'progressbar.acknowledgement'  | translate }}</p>
                        <!-- <img src="../../../../assets/images/white_plane.png" class="white_planeeee" alt="white_plane"> -->
                        <div class="right"></div>
                    </div>

                </div>
            </div>

            <div class="status_text_n">
                <p>{{currentStatus}}</p>
            </div>
        </div>
    </div>
</div>
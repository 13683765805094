<footer class="site-footer">
    <div class="footer-top">
        <div class="site-footer__inner">
            <nav class="site-footer__menu main-nav" *ngIf="isProd">
                <a href="{{'footer.acceptable-prod-link' | translate}}" target="_blank">{{'footer.acceptable-heading' |
                    translate}}</a>
                <a href="{{'footer.cookie-prod-link' | translate}}" target="_blank">{{'footer.cookie-heading' |
                    translate}}</a>
                <a href="{{'footer.end-user-prod-link' | translate}}" target="_blank">{{'footer.end-user-heading' |
                    translate}}</a>
                <a href="{{'footer.personal-prod-link' | translate}}" target="_blank">{{'footer.personal-heading' |
                    translate}}</a>
                <a href="{{'footer.premium-prod-link' | translate}}" target="_blank">{{'footer.premium-heading' |
                    translate}}</a>
                <a href="{{'footer.privacy-prod-link' | translate}}" target="_blank">{{'footer.privacy-heading' |
                    translate}}</a>
                <a href="{{'footer.security-prod-link' | translate}}" target="_blank">{{'footer.security-heading' |
                    translate}}</a>
                <a href="{{'footer.tnc-prod-link' | translate}}" target="_blank">{{'footer.tnc-heading' |
                    translate}}</a>
            </nav>

            <nav class="site-footer__menu main-nav" *ngIf="!isProd">
                <a href="{{'footer.acceptable-uat-link' | translate}}" target="_blank">{{'footer.acceptable-heading' |
                    translate}}</a>
                <a href="{{'footer.cookie-uat-link' | translate}}" target="_blank">{{'footer.cookie-heading' |
                    translate}}</a>
                <a href="{{'footer.end-user-uat-link' | translate}}" target="_blank">{{'footer.end-user-heading' |
                    translate}}</a>
                <a href="{{'footer.personal-uat-link' | translate}}" target="_blank">{{'footer.personal-heading' |
                    translate}}</a>
                <a href="{{'footer.premium-uat-link' | translate}}" target="_blank">{{'footer.premium-heading' |
                    translate}}</a>
                <a href="{{'footer.privacy-uat-link' | translate}}" target="_blank">{{'footer.privacy-heading' |
                    translate}}</a>
                <a href="{{'footer.security-uat-link' | translate}}" target="_blank">{{'footer.security-heading' |
                    translate}}</a>
                <a href="{{'footer.tnc-uat-link' | translate}}" target="_blank">{{'footer.tnc-heading' | translate}}</a>
            </nav>
            <div class="site-footer__confidential"><span class="yr-blck">© {{currentYear}}
                {{'footer.copy_right' | translate}}</span><br><span class="footer-quote">{{'footer.copy_right_1' |
                translate}}</span></div>
        </div>
        <!-- <div class="site-footer__inner">
            
        </div> -->
    </div>
    <!-- <div class="footer-bottom">
        
    </div> -->
</footer>